.bungee-font {
    font-family: 'Bungee', cursive;
}

.order {
    font-family: 'Bungee', cursive;
    display: flex;
    width: 500px;
    height: 100px;
    margin: 18px 0 10px 0;
    background: linear-gradient(153deg, rgba(245,113,46,1) 0%, rgba(242,53,47,1) 25%, rgba(165,64,159,1) 100%);
    color: white;
    transform: translateX(100%);
    animation: slide 6s forwards;
}

.order-summary {
    flex-grow: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-items {
    transform: translateY(-18px);
    flex-grow: 0.2;
    max-width: 100px;
    height: 128px;
    background: linear-gradient(-27deg, rgba(245,113,46,1) 0%, rgba(242,53,47,1) 25%, rgba(165,64,159,1) 100%);
}

.line-item {
    padding: 4px;
}

.line-item > img {
    max-width: 100px;
    max-height: 120px;
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }
    20% {
        transform: translateX(0%);
    }
    80% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}